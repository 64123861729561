import T from 'types'
import SurveyTracker from '../interface'
import AbstractTracker from '../AbstractTracker'
import { onboardingSpecialty, specialtyToFamily, storePatientAttributes } from 'utils/trackers/utils'

class SnapchatTracker extends AbstractTracker implements SurveyTracker {

  initialized: boolean
  config: string | undefined

  constructor() {
    super()
    this.initialized = false
    this.config = process.env.REACT_APP_SNAPCHAT_PIXEL_ID
  }
  private enabled(): boolean {
    return (process.env.REACT_APP_LIVE_PROD_ENVIRONMENT === "true") && (typeof window.snaptr !== 'undefined')
  }

  private initialize() :void {
    if (!this.config || !this.enabled()) {
      console.warn('Please insert pixel id for initializing');
    } else {
      const patientAttributes: any = storePatientAttributes()
      window.snaptr('init', this.config, {
        user_email: patientAttributes.email,
        user_phone_number: patientAttributes.phoneNumber
      });
      this.initialized = true;
    }
  }

  public trackLead(specialty: string) {
    const family = specialtyToFamily(specialty)
    this.track('VIEW_CONTENT', {
      item_category: family
    })
    this.track('START_CHECKOUT', {
      item_category: family
    })
  }

  public trackDidPayConsultation(
    transactionId: number | any,
    transactionPrice: string,
    consultationType: string,
    medium: string,
    specialty: string,
  ) {
    const family = specialtyToFamily(specialty)
    this.track('PURCHASE', {
      item_category: family,
      transaction_id: transactionId,
      price: transactionPrice,
      currency: 'EUR',
    })
  }

  private track = (title: string, data: any): void => {
    if(!this.initialized){
      this.initialize()
    }
    if(this.initialized){
      window.snaptr('track', title, data)
    }
  }
}

export default SnapchatTracker
