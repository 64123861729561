export const TEST_CARDS = [
  '4706750000000009',
  '4706750000000033',
  '4706750000000025',
  '4706750000000017',
  '3569990000000132',
  '3569990000000157',
  '4970100000000154',
  '4970101122334422',
  '4970101122334406',
  '4970101122334414',
  '3012340000000000',
  '3012349999999999',
]
