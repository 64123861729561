import { filter, get, last, sortBy, map, parseInt } from 'lodash'
import moment from 'constants/moment'
import {
  PRESCRIPTION_TYPE_ERECTION_DRUGS,
  PRESCRIPTION_TYPE_PHYTOTHERAPY,
} from 'constants/prescriptions'
import T from 'types'
import { CookiesHelper } from 'helpers'

export const lastConsultation = (collection: T.Consultation[]) =>
  last(filter(collection, (o: T.Consultation) =>
    moment(get(o, 'appointment')) > moment(new Date()) && get(o, 'status') !== 'canceled',
  ),
  )

export const lastErectionDrugsPrescription = (collection: T.Prescription[]) => {
  return lastPrescriptionOfType(collection, PRESCRIPTION_TYPE_ERECTION_DRUGS)
}

export const lastPhytotherapyPrescription = (collection: T.Prescription[]) => {
  return lastPrescriptionOfType(collection, PRESCRIPTION_TYPE_PHYTOTHERAPY)
}

export const lastPrescriptionWithMolecule = (collection: T.Prescription[], molecule: string) => {
  if (collection == []) return
  return last(
    sortBy(
      filter(
        collection,
        (p: T.Prescription) => moment(get(p, 'expiredAt')) > moment(new Date())
          && get(p, 'prescriptionItems[0].molecule') === molecule
      ),
      (p: T.Prescription) => moment(get(p, 'createdAt')).format('YYYYMMDDHHmmss'),
    ),
  )
}

export const lastPrescriptionOfType = (collection: T.Prescription[], prescriptionType: string) => {
  return last(
    sortBy(
      filterPrescriptionByType(collection, [prescriptionType], false),
      (p: T.Prescription) => moment(get(p, 'createdAt')).format('YYYYMMDDHHmmss'),
    ),
  )
}

export const filterPrescriptionByType = (
  collection: T.Prescription[],
  prescriptionTypes: string[],
  includeExpired: boolean
): T.Prescription[] => {
  return filter(
    collection,
    (p: T.Prescription) =>
      (includeExpired || moment(get(p, 'expiredAt')) > moment(new Date()))
      && prescriptionTypes.includes(get(p, 'prescriptionType'))
  )
}

export const prescriptionPdfUrl = (prescription: T.Prescription) => {
  return `${prescription.downloadUrl}?access_token=${CookiesHelper.getPureOauthAccessToken()}`
}
