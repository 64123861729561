import { handle } from 'redux-pack'
import { get, isNil, map, merge } from 'lodash'
import * as A from 'constants/actions'
import T from 'types'
import pathologies from './data/pathologies'
import {
  COMPLEX,
  SIMPLE,
} from 'constants/doctorSelectionStates'

type Action = any


const initialState: T.ConsultationsState = {
  collection: [],
  specialtyCategories: pathologies,
  focusedItem: null,
  focusedOrder: null,
  loading: false,
  error: null,
}

export default (state = initialState, action: Action) => {
  const { payload } = action
  switch (action.type) {
    case A.SEND_CONSULTATION_PAYMENT:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          focusedOrder: payload.order,
          consultationPaymentSuccess: isNil(payload.order.gatewaySecureModeRedirectUrl),
          paymentMethod: payload.paymentMethod,
        }),
      })
    case A.SET_CONSULTATION:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          focusedItem: payload.consultation,
          success: true,
        }),
      })
    case A.STORE_PAID_CONSULTATION_SPECIALTY:
      return {
        ...state,
        paidSpecialties: payload,
      }
    case A.STORE_PENDING_CONSULTATION_SPECIALTY:
      return {
        ...state,
        pendingSpecialties: payload,
      }
    case A.SET_CONSULTATION_FROM_PROFILE:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false, loading: false }),
        success: prevState => ({
          ...prevState,
          focusedItem: payload.consultation,
          newConsultationSuccess: true,
        }),
      })
    case A.GET_CONSULTATIONS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          collection: payload.consultations,
          success: true,
        }),
      })
    case A.UPDATE_FOCUSED_CONSULTATION:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          focusedItem: payload.consultation,
        }),
      })
    case A.UPDATE_CONSULTATION_WITH_SSN:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          focusedItem: payload.consultation,
        }),
      })
    case A.GET_LAST_CONSULTATION:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          collection: payload.consultations,
          success: true,
        }),
      })
    case A.UPDATE_CONSULTATION:
      return {
        ...state,
        collection: map(state.collection, consultation =>
          consultation.id === action.consultation.id ? action.consultation : consultation,
        ),
      }
    // case when consultation is sent with the survey
    case A.SET_SURVEY:
      return handle(state, action, {
        success: prevState => {
          let newState = {
            ...prevState,
            consultationPaymentSuccess: get(payload.order, 'state') === 'complete' || false,
          }
          // When set_survey return no consultation, prevent nullifying the focusedItem
          // (open_consultation, go to payment, reload the page, => focusedItem was empty)
          if(!isNil(payload.consultation)){
            newState = merge(newState, {
              focusedItem: payload.consultation
            })
          }
          return newState
        },
      })
    case A.SET_OPEN_CONSULTATION_DOCTOR:
      return {
        ...state,
        skipDoctorSelection: true,
        consultationType: COMPLEX
      }
    case A.RESET_OPEN_CONSULTATION_DOCTOR:
      return {
        ...state,
        skipDoctorSelection: false,
        consultationType: SIMPLE
      }
    default:
      return state
  }
}
