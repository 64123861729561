import T from 'types'
import { Model } from 'survey-core'
import SurveyTracker from '../interface'
import { MediaConfig, MediaSetupTrackingContext } from 'helpers/hooks/useVisioConfiguration';
class AbstractTracker implements SurveyTracker {
  public identifyUser(userId: string, traits: object) {}
  public trackPageViewV1(pageTitle: string, pagePath: string) {}
  public trackPageViewV2(category: string, pagePath: string) {}
  public trackDidEnterOnboarding(trackAddToCart: boolean): void {}
  public trackQuestionView(sender: Model, step: number, options: any) {}
  public trackDidStartPrePaymentSurvey(): void {}
  public trackDidFillPrePaymentSurvey(): void {}
  public trackAcademyStepView(programName: string, step: number, subStep?: string) {}
  public trackOnboardingStepView(step: number, subStep?: string) {}
  public trackAddToCart() {}
  public trackLead(specialty: string) {}
  public trackAcademyLead(program: T.Program) {}
  public trackChoseConsultationType(consultationType: string, medium: string) {}
  public trackChoseMedium(consultationType: string, medium: string) {}
  public trackChoseDoctor(consultationType: string, medium: string) {}
  public trackCreatedConsultation(consultationType: string, medium: string) {}
  public trackDidPayConsultation(
    transactionId: number | any,
    transactionPrice: string,
    consultationType: string,
    medium: string,
    specialty: string,
    paymentMethod?: string,
  ) {}
  public trackDidPayAcademyProgram(
    transactionId: number | any,
    transactionPrice: string,
    program: T.Program,
    variant: T.Variant,
    subscriptionEvent?: string,
    orderType?: string
  ) {}
  trackProgramPageClicked(
    programSlug: string,
    purchasedProgram: boolean,
    clickOrigin: string,
  ) {}
  public trackDidPayPhytotherapy(
    revenue: string,
    orderId: string,
    orderType: string,
    firstOfType: boolean,
    products: T.SegmentPhytotherapyProductsProperties[]
  ) {}
  trackPostPaymentInfosUpdate(hasDataChanged: boolean) {}
  trackPostPaymentInfosEdit() {}
  trackCameraConfigurationSucceeded(): void {}
  trackCameraConfigurationFailed(errorName: string, errorMessage: string): void {}
  trackMediaSetupEvent(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {}
  trackMediaSetupEventDeviceMissing(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {}
  trackMediaSetupEventSucceeded(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {}
  trackMediaSetupEventSkipped(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {}
}

export default AbstractTracker
