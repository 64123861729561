export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: any) => {
  try {
    const {
      academy,
      doctors,
      onboarding,
      storefront,
      prescriptions: {
        collectionPrescription,
        focusedPrescription,
        lastPrescription,
        lastPhytotherapyPrescription,
      },
      surveys: {
        focusedSurvey,
        focusedSurveySubmission,
      },
      consultations: {
        specialtyCategories,
        collection,
        paidSpecialties,
        pendingSpecialties,
        focusedItem,
        focusedOrder,
        consultation },
      ux: {
        lastFocusedLocation,
      },
      unifiedCheckout: {
        orderId,
        pharmacistId,
        frenchCitizen,
        forPrescriptions,
        lastFinishedConsultationId,
        lastFinishedConsultationFetchedOnce
      },
      events
    } = state
    const filteredState = {
      academy: {
        ...academy,
        actionLoading: []
      },
      doctors,
      onboarding,
      storefront: {
        ...storefront,
        actionLoading: []
      },
      prescriptions: {
        collectionPrescription,
        focusedPrescription,
        lastPrescription,
        lastPhytotherapyPrescription,
      },
      surveys: {
        focusedSurvey,
        focusedSurveySubmission,
      },
      consultations: {
        focusedItem,
        focusedOrder,
        collection,
        paidSpecialties,
        consultation,
        specialtyCategories,
      },
      ux: {
        lastFocusedLocation,
      },
      unifiedCheckout: {
        orderId,
        pharmacistId,
        frenchCitizen,
        forPrescriptions,
        lastFinishedConsultationId,
        lastFinishedConsultationFetchedOnce
      },
      events
    }
    const serializedState = JSON.stringify(filteredState)
    sessionStorage.setItem('state', serializedState)
  } catch {
    // ignore write errors
  }
}
