import React, { Component } from 'react'
import T from 'types'
interface IProps {
  icon: string | T.IconsMap,
  classname?: string,
  onClick?: (e?: any) => void,
}

class SvgIcon extends Component<IProps> {
  public render() {
    const { icon, classname, onClick } = this.props
    if(typeof(icon) !== 'string'){
      throw new Error(`Implementation Error - icon not of type string.`)
    }
    return (
      <span
        className={classname}
        dangerouslySetInnerHTML={{ __html: (icon as string) }}
        onClick={() => onClick && onClick()}
      />
    )
  }
}

export default SvgIcon
