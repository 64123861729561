import React from 'react'
import moment from 'constants/moment'
import T from 'types'
import { head, last, map, sample, isEmpty, isNil } from 'lodash'
import Dinero from 'dinero.js'
import { I18n } from 'react-redux-i18n'
import {
  formatedCollection,
  dateFormater,
  renderLegendPrescriptions,
  renderLegendLastPrescription,
  renderLegendConsultations,
  renderPrescriptionsBody,
  renderConsultationsBody,
  teleconsultationTitle,
} from './helperComponents/tableHelpers'

import {
  lastConsultation,
  lastPhytotherapyPrescription,
  lastErectionDrugsPrescription,
  prescriptionPdfUrl,
} from './helperComponents/lastConsultationHelpers'

import { displayPhone, renderDoctorAvailability } from './helperComponents/teleconsultationHelpers'

import {
  createPaymentRequestPayload,
  getPaymentMethod
} from './helperApiService/paymentHelper'

import {
  isCreditCardNumberValid,
  isCreditCardHolderNameValid,
  isCreditExpiryDateValid,
  isCreditCardCVVValid
} from './helperComponents/paymentFormHelpers'

const formatFrenchDate = (date: string) => {
  return moment(new Date(date))
    .locale('fr')
    .format('L')
}

const formatPrice = (price: number): string => {

  const cents = price * 100
  const integerCents = Math.floor(cents)
  return Dinero({ amount: integerCents }).toFormat()
}

const formatedOpenedHours = (pharmacy: T.Pharmacist) => {
  const { openingTime, closingTime, openingDays } = pharmacy
  const openedDay = head(openingDays)
  const closedDay = last(openingDays)
  const days = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']

  return (
    <h4>
      Ouverte du {days[openedDay]} au {days[closedDay]} de {openingTime} à {closingTime}
    </h4>
  )
}

const buildPatientAttributes = (profile: T.Profile) => {
  const {
    email,
    firstName,
    lastName,
    ssn,
    phoneNumber,
    creditCards,
    birthDate,
    isAcademy,
    hasSommeilProgram,
    hasTimeProgram,
    hasMasterProgram,
    hasVaginismeProgram
  } = profile

  return {
    email,
    firstName,
    lastName,
    birthDate,
    ssn,
    phoneNumber,
    creditCards,
    isAcademy,
    hasSommeilProgram,
    hasTimeProgram,
    hasMasterProgram,
    hasVaginismeProgram,
    termsOfService: true,
  }
}

const buildJsonFormData = (data: FormData) => {
  const dataObject = {} as any
  data.forEach((value, key) => {
    const newKey = key.split('.').pop()
    dataObject[newKey as string] = value
  })

  return dataObject
}

const randomSelector = <T extends unknown>(
  list: T[],
  weight: (element: T) => number,
): T | undefined => {
  let totalWeight = 0

  const weights = map(list, element => {
    const currentWeight = weight(element)
    totalWeight += currentWeight
    return currentWeight
  })

  let random = Math.random() * totalWeight

  for (let i = 0; i < weights.length; i = i + 1) {
    if (random < weights[i]) {
      return list[i]
    }

    random -= weights[i]
  }

  return sample(list)
}

const titleForConsultationCategory = (slug: string) => {
  const k = (isEmpty(slug) ? 'sexual' : slug)

  return I18n.t(`consultationCategory.${k}`)
}

const buildAuthParams = (authParams: T.AuthParams): any => {
  const oneTimeAuthToken = (authParams as T.AuthParamsOneTimeAuthToken).oneTimeAuthToken
  if (isNil(oneTimeAuthToken)) {
    return authParams
  }
  else {
    return { authToken: oneTimeAuthToken }
  }
}

const parseCSV = (str: string) => {
  const arr: any[] = []
  let quote = false  // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (let row = 0, col = 0, c = 0; c < str.length; c++) {
    const cc = str[c]
    const nc = str[c + 1]        // Current character, next character
    arr[row] = arr[row] || []             // Create a new row if necessary
    arr[row][col] = arr[row][col] || ''   // Create a new column if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc == '"' && quote && nc == '"') { arr[row][col] += cc; ++c; continue }

    // If it's just one quotation mark, begin/end quoted field
    if (cc == '"') { quote = !quote; continue }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (cc == ',' && !quote) { ++col; continue }

    // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
    // and move on to the next row and move to column 0 of that new row
    if (cc == '\r' && nc == '\n' && !quote) { ++row; col = 0; ++c; continue }

    // If it's a newline (LF or CR) and we're not in a quoted field,
    // move on to the next row and move to column 0 of that new row
    if (cc == '\n' && !quote) { ++row; col = 0; continue }
    if (cc == '\r' && !quote) { ++row; col = 0; continue }

    // Otherwise, append the current character to the current column
    arr[row][col] += cc;
  }
  return arr;
}

export {
  buildAuthParams,
  createPaymentRequestPayload,
  getPaymentMethod,
  dateFormater,
  formatedCollection,
  formatFrenchDate,
  formatPrice,
  formatedOpenedHours,
  buildJsonFormData,
  buildPatientAttributes,
  displayPhone,
  lastConsultation,
  lastErectionDrugsPrescription,
  lastPhytotherapyPrescription,
  prescriptionPdfUrl,
  renderDoctorAvailability,
  renderLegendPrescriptions,
  renderLegendLastPrescription,
  renderLegendConsultations,
  renderPrescriptionsBody,
  renderConsultationsBody,
  randomSelector,
  isCreditCardNumberValid,
  isCreditCardHolderNameValid,
  isCreditExpiryDateValid,
  isCreditCardCVVValid,
  teleconsultationTitle,
  titleForConsultationCategory,
  parseCSV
}
