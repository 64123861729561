import { handle } from "redux-pack"
import { get } from "lodash"
import T from 'types'
import moment from "constants/moment"
import normalizedDataHandler from "./helpers/normalizedDataHandler"
import * as A from 'constants/actions'

const initialState: T.AcademyStore = {
  loading: false,
  success: null,
  error: null,
  actionLoading: [],
  sleepCalendar: {},
  sleepCalendarItems: {},
  onboarding: {
    slug: 'time-program',
  },
}

export default (state = initialState, action: any) => {
  const { payload } = action

  switch (action.type) {
    case A.GET_PROGRAMS:
      return normalizedDataHandler(initialState)(state, action, {
        programLoadedAt: moment().toDate(),
      })
    case A.APPLY_COUPON:
    case A.SELECT_PROGRAM_VARIANT:
    case A.GET_ENROLLMENTS:
    case A.ENROLL_PROGRAM:
    case A.PURCHASE_PROGRAM:
    case A.EVALUATE_LESSON:
    case A.SET_SUPPORT_MESSAGE:
    case A.START_RENEW_ENROLLMENT:
    case A.INTERRUPT_ENROLLMENT:
    case A.GET_PAYMENT_METHODS:
    case A.CREATE_DELIVERY:
    case A.GET_SLEEP_CALENDAR: // TODO: empty ols sleep calendar items when we fetch the calendar
    case A.CREATE_SLEEP_CALENDAR_ITEM:
      return normalizedDataHandler(initialState)(state, action, {})
    case A.SET_PERSONAL_DATA:
      return {
        ...state,
        patientAttributes: payload,
      }
    case A.SET_EXTRA_PERSONAL_DATA:
      return {
        ...state,
        patientExtraAttributes: payload,
      }
    case A.START_ONBOARDING_WITH_PROGRAM:
      return {
        ...state,
        onboarding: {
          slug: payload
        }
      }
    default:
      return state
  }
}

// return handle(state, action, {
//   start: prevState => ({
//     ...prevState, loading: true, error: null, success: null,
//   }),
//   finish: prevState => ({ ...prevState, loading: false }),
//   failure: prevState => ({ ...prevState, error: payload, success: false }),
//   success: prevState => ({
//     ...prevState,
//     ...payload,
//     success: true,
//   }),
// })
