import { handle} from 'redux-pack'
import { uniqBy, without, concat  } from 'lodash'
import * as A from 'constants/actions'
import T from 'types'

type Action = {
  message: T.Message,
  meta: any,
  payload: any,
  type: string,
}

type State = {
  messages: T.Message[],
  actionLoading: string[]
}

const initialState: State = {
  messages: [],
  actionLoading: []
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case A.APPEND_MESSAGE:
      return ({
        ...state,
        messages: uniqBy(
          [
            ...state.messages,
            action.message,
          ],
          'id',
        ),
      })
    case A.CREATE_MESSAGE:
      return handle(state, action, {
        start: prevState => ({ ...prevState, actionLoading: concat(prevState.actionLoading, [action.type]) }),
        finish: prevState => ({ ...prevState, actionLoading: without(prevState.actionLoading, action.type), }),
        failure: prevState => ({ ...prevState }),
        success: prevState => ({
          ...prevState,
          messages: uniqBy(
            [
              ...prevState.messages,
              action.payload.data,
            ],
            'id',
          ),
        }),
      })
    case A.GET_MESSAGES:
      return handle(state, action, {
        start: prevState => ({ ...prevState }),
        finish: prevState => ({ ...prevState }),
        failure: prevState => ({ ...prevState }),
        success: prevState => ({
          ...prevState,
          messages: uniqBy(
            [
              ...prevState.messages,
              ...action.payload.data,
            ],
            'id',
          ),
        }),
      })
    default:
      return state
  }
}
