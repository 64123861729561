import { handle } from 'redux-pack'
import * as A from 'constants/actions'
import { lastErectionDrugsPrescription, lastPhytotherapyPrescription } from 'utils/helpers'

type Action = any

type State = {
  loading: boolean | null
  success: boolean | null
  error: string | null
}

const initialState: State = {
  loading: null,
  success: null,
  error: null,
}

export default (state = initialState, action: Action) => {
  const { payload } = action

  switch (action.type) {
    case A.GET_PRESCRIPTIONS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          collectionPrescription: payload.prescriptions,
          lastPrescription: lastErectionDrugsPrescription(payload.prescriptions),
          lastPhytotherapyPrescription: lastPhytotherapyPrescription(payload.prescriptions),
          success: true,
        }),
      })
    case A.SET_FOCUSED_PRESCIPTION:
      return {
        ...state,
        focusedPrescription: payload,
      }
    case A.SHOW_PRESCRIPTION:
      return {
        ...state,
        focusedPrescription: (payload) ? payload.data : undefined,
      }
    case A.GET_PRESCRIPTION:
      return {
        ...state,
        focusedPrescription: payload,
      }
    case A.CREATE_PRESCRIPTION_DELIVERY:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          focusedDeliveryOrder: payload.data,
          success: true,
        }),
      })
    case A.FINALIZE_PRESCRIPTION_DELIVERY:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          focusedDeliveryOrder: null,
          success: true,
        }),
      })
    case A.START_PURCHASE_PRESCRIPTION_DOWNLOAD:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          focusedPrescription: payload.data,
          success: true,
        }),
      })
    case A.PURCHASE_PRESCRIPTION_DOWNLOAD:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => prevState,
      })
    default:
      return state
  }
}
