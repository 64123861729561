import React, { Fragment } from 'react'
import { get, isUndefined, map } from 'lodash'
import { I18n } from 'react-redux-i18n'
import moment from 'constants/moment'
import T from 'types'
import { Col } from 'react-bootstrap'
import SvgIcon from '../../components/SvgIcon'
import { getIcon } from '../../assets/icons'
import cx from 'classnames'

export const dateFormater = (date: any, format: string) =>
  moment(date).isValid() ? moment(date).format(format) : date

export const formatedCollection = (collection: any[], columns: any[]) =>
  map(collection, obj =>
    columns.reduce(
      (acc: string[][], field) => [...acc, [field, get(obj, field === 'cta' ? 'id' : field)]],
      [],
    ),
  )

export const renderLegendLastPrescription = (practician: string) => (
  <tr className="legend-table">
    <th>Votre ordonnance</th>
    <th>{practician}</th>
    <th>En date du</th>
    <th>Expiration</th>
  </tr>
)

export const renderLegendPrescriptions = (practician: string) => (
  <thead>
    <tr className="legend-table">
      <th>{practician}</th>
      <th>Date</th>
      <th>Validité</th>
      <th>{I18n.t('prescriptions.history.prescriptionType')}</th>
      <th>{I18n.t('prescriptions.history.prescription')}</th>
      <th />
    </tr>
  </thead>
)

export const renderLegendConsultations = (practician: string) => (
  <thead>
    <tr className="legend-table">
      <th>Date du rendez-vous</th>
      <th>Catégorie</th>
      <th>{practician}</th>
      <th>État</th>
      <th />
    </tr>
  </thead>
)

export const renderPrescriptionsBody = (prescription: object) => (
  <Fragment>
    <div className="doctor-profile-div d-flex flex-row align-items-center justify-content-center justify-content-md-start justify-content-lg-start mb-3 mb-lg-0">
      <img
        className="doctor-profile-picture-prescription"
        src={get(prescription, 'doctorAvatarUrl')}
      />
      <div className="d-flex flex-column">
        <div className="doctor-profile-specialty-name">{get(prescription, 'doctor')}</div>
        <div className="doctor-profile-specialty-prescription">
          {get(prescription, 'doctorSpecialty')}
        </div>
      </div>
    </div>
    <div className="align-middle d-flex align-items-center prescription-availability-div justify-content-center justify-content-md-end justify-content-lg-start mb-3 mb-lg-0">
      <SvgIcon classname="prescription-history-row-icon" icon={getIcon('clinic.hourglass')} />
      {get(prescription, 'overTheCounter') ? (
        <div>Pas de date d’expiration</div>
      ) : (
        <div>
          Ordonnance valable du
          <br />
          <span className="prescription-availability-date">
            {dateFormater(get(prescription, 'createdAt'), 'DD/MM/YY')} au{' '}
            {dateFormater(get(prescription, 'expiredAt'), 'DD/MM/YY')}
          </span>
        </div>
      )}
    </div>
  </Fragment>
)

export const renderConsultationsBody = (consultation: object, links: any[]) => {
  const status = `status.${get(consultation, 'state')}`
  return (
    <Fragment>
      <Col
        xs={12}
        md={6}
        lg={3}
        className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start justify-content-md-start mb-3 mb-lg-0"
      >
        <img
          className="doctor-profile-picture-prescription"
          src={get(consultation, 'doctorAvatarUrl')}
        />
        <div className="d-flex flex-column">
          <div className="doctor-profile-specialty-name">{get(consultation, 'doctor')}</div>
          <div className="doctor-profile-specialty-prescription">
            {get(consultation, 'doctorSpecialty')}
          </div>
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={3}
        className="d-flex align-items-center justify-content-center mb-3 justify-content-md-end justify-content-lg-center"
      >
        <SvgIcon
          icon={getIcon('clinic.bulletCalendar')}
          classname="consultation-calendar-picture"
        />
        <div className="consultation-calendar-text">
          Rendez vous du{' '}
          <span className="consultation-calendar-date">
            {dateFormater(get(consultation, 'appointmentAt'), 'L')}
          </span>
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={{ span: 'auto', order: 'last' }}
        className="consultation-history-link d-flex justify-content-center mb-4 justify-content-md-start"
      >
        {links.length > 0 && (
          <>
            <SvgIcon
              classname="prescription-history-row-icon d-flex align-items-center"
              icon={getIcon('clinic.eye')}
            />
            <div className="consultation-advise-card">
              {links
                .map<React.ReactNode>(el => el)
                .reduce((prev, curr) => [
                  prev,
                  <span className="mb-2">
                    <br />
                    <div className="mb-2" />
                  </span>,
                  curr,
                ])}
            </div>
          </>
        )}
      </Col>
      <Col
        xs={12}
        md={6}
        lg="auto"
        className="d-flex justify-content-center justify-content-md-end mb-3 justify-content-lg-center"
      >
        <div
          className={cx(
            'consultation-status-label',
            { 'confirmed-status-label': status === 'status.confirmed' },
            { 'started-status-label': status === 'status.started' },
            { 'finished-status-label': status === 'status.finished' },
          )}
        >
          {I18n.t(`status.${get(consultation, 'state')}`)}
        </div>
      </Col>
    </Fragment>
  )
}

export const teleconsultationTitle = (consultation: T.Consultation) => {
  if (isUndefined(consultation)) {
    return 'Ma consultation'
  }

  switch (consultation.state) {
    case 'confirmed':
      return 'Ma prochaine consultation'
    default:
      return 'Ma consultation'
  }
}
