import T from 'types'
import { get, isUndefined } from 'lodash'
import SurveyTracker from '../interface'
import AbstractTracker from '../AbstractTracker'
import { specialtyToFamily } from 'utils/trackers/utils'

const tiktokTrackingMap = {
  consultation: {
    lead: {
      sexual: 'sexual',
    },
    booking: {
      sexual: 'sexual'
    },
  },
}

class TiktokTracker extends AbstractTracker implements SurveyTracker {

  config: string | undefined

  constructor() {
    super()
    this.config = process.env.REACT_APP_TIKTOK_PIXEL_ID
  }

  private enabled(): boolean {
    return (!!this.config) && (typeof window.ttq !== 'undefined')
  }

  public trackLead(specialty: string) {
    const family = specialtyToFamily(specialty)
    const contentId = get(tiktokTrackingMap.consultation.lead, family)

    this.track('InitiateCheckout', {
      content_type: 'product',
      content_id: contentId,
      content_category: "consultation",
      content_name: contentId,
    })
  }

  public trackDidPayConsultation(
    transactionId: number | any,
    transactionPrice: string,
    consultationType: string,
    medium: string,
    specialty: string,
  ) {
    const family = specialtyToFamily(specialty)
    const contentId = get(tiktokTrackingMap.consultation.booking, family)

    this.track('CompletePayment', {
      content_type: 'product',
      content_id: contentId,
      content_category: "consultation",
      content_name: contentId,
      quantity: 1,
      price: transactionPrice,
      currency: 'EUR'
    })
  }

  private track = (name: string, data: any): void => {
    if (this.enabled() && !isUndefined(get(data, 'content_id'))) {
      window.ttq.track(name, data)
    }
  }

}

export default TiktokTracker
