import { IS_CHARLES_PLATFORM, SIMPLE_CONSULTATION_PRICE } from 'constants/config'
import T from 'types'

const charlesPathologies: T.SpecialtyCategory[] = [
  {
    title: 'Santé sexuelle',
    slug: 'sexual',
    icon: 'clinic.specialties.sexuality',
    theme: 'c-blue',
    specialties: [
      {
        name: 'Problèmes d\'érection',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'erectile_dysfunction',
        medical: true,
        priority: 'top',
      },
      {
        name: 'Éjaculation précoce',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'premature_ejaculation',
        medical: true,
        priority: 'top',
      },
      {
        name: 'Andropause',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'andropause',
        medical: true,
        priority: 'top',
      },
      {
        name: 'Fertilité',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'fertility',
        medical: true,
        priority: 'top',
      },
      {
        name: 'Baisse de libido',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'libido',
        medical: true,
        priority: 'top',
      },
      {
        name: 'Addiction au porno',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'porn_addiction',
        medical: true,
      },
      {
        name: 'Manque de plaisir',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'lack_of_pleasure',
        medical: true,
      },
      {
        name: 'Éjaculation impossible',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'no_ejaculation',
        medical: true,
      },
      {
        name: 'Taille ou courbure du pénis',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'penile_curvature',
        medical: true,
      },
      {
        name: 'Autre pathologie',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'other',
        medical: true,
      },
      {
        name: 'Consultation libre',
        slug: 'open_consultation',
        medical: true,
        openSpecialty: true,
      },
      {
        name: 'Thérapie de couple',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'charles_couple_therapy',
        priority: 'top',
        medical: false,
      },
      {
        name: 'Thérapie psycho-sexo',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'charles_psycho_sexo',
        priority: 'top',
        medical: false,
      },
    ],
  },
  {
    title: 'Sommeil',
    slug: 'sleep',
    icon: 'clinic.specialties.sleep',
    theme: 'c-rust',
    specialties: [
      {
        name: 'Sommeil',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'sleep',
        medical: true,
        priority: 'top',
      },
    ],
  },
  {
    title: 'Cheveux',
    slug: 'hair',
    icon: 'clinic.specialties.hair',
    theme: 'c-yellow',
    specialties: [
      {
        name: 'Chute de cheveux',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'hair',
        medical: true,
        priority: 'top',
      },
    ],
  },
  {
    title: 'Poids',
    slug: 'weight',
    icon: 'clinic.specialties.weight',
    theme: 'c-green',
    specialties: [
      {
        name: 'Perte de poids',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'weight',
        medical: true,
        priority: 'top',
      },
    ],
  },
  {
    title: 'Digestion',
    slug: 'digestion',
    icon: 'clinic.specialties.digestion',
    theme: 'c-green',
    featurePreview: true,
    specialties: [
      {
        name: 'Digestion',
        price: `${SIMPLE_CONSULTATION_PRICE}€`,
        slug: 'digestion',
        medical: true,
        priority: 'top',
      },
    ],
  },
]

export const miaPathologies: T.SpecialtyCategory[] = [
  {
    title: 'Santé sexuelle',
    slug: 'sexual',
    icon: 'clinic.specialties.sexuality',
    ignoreMedicalType: true,
    theme: 'c-blue',
    specialties: [
      {
        name: 'Bien-être sexuel',
        slug: 'sexual_wellness',
        medical: false,
        priority: 'top',
      },
      {
        name: 'Baisse de libido',
        slug: 'sexual_desire',
        medical: false,
        priority: 'top',
      },
      {
        name: 'Douleurs lors des rapports',
        slug: 'pain',
        medical: false,
        priority: 'top',
      },
      {
        name: 'Thérapie de couple',
        slug: 'couple_therapy',
        medical: false,
        priority: 'top',
      },
      {
        name: 'Difficultés à prendre du plaisir',
        slug: 'reach_orgasm',
        medical: false,
        priority: 'top',
      },
      {
        name: 'Autre',
        slug: 'mia_other',
        medical: false,
        priority: 'top',
      },
    ],
  },
]

const pathologies = IS_CHARLES_PLATFORM ? charlesPathologies : miaPathologies
export default pathologies
