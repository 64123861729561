export const AUTH_GET_PROFILE = "AUTH_GET_PROFILE"
export const GET_ORDERS = "GET_ORDERS"
export const STORE_ONE_TIME_AUTH_TOKEN = "STORE_ONE_TIME_AUTH_TOKEN"
export const AUTH_TOKEN = "AUTH_TOKEN"
export const AUTH_SIGNIN = "AUTH_SIGNIN"
export const AUTH_SIGNOUT = "AUTH_SIGNOUT"
export const AUTH_UPDATE_FIELD = "AUTH_UPDATE_FIELD"
export const SET_SIGNED_OUT = "SET_SIGNED_OUT"
export const UX_TOGGLE_SIDEBAR = "UX_TOGGLE_SIDEBAR"
export const UX_HIDE_NOTIFICATION = "UX_HIDE_NOTIFICATION"
export const UX_SHOW_NOTIFICATION = "UX_SHOW_NOTIFICATION"
export const UX_HIDE_POPUP = "UX_HIDE_POPUP"
export const UX_SHOW_POPUP = "UX_SHOW_POPUP"
export const TOGGLE_DROPDOWN_SIDEBAR_ELEMENT = "TOGGLE_DROPDOWN_SIDEBAR_ELEMENT"
export const REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN'
export const STORE_LAST_FOCUSED_LOCATION = 'STORE_LAST_FOCUSED_LOCATION'
export const REDIRECTED_AFTER_LOGIN = 'REDIRECTED_AFTER_LOGIN'

//Post Assistance message to API
export const SET_SUPPORT_MESSAGE = "SET_SUPPORT_MESSAGE"

// GET doctors collection from API
export const SET_DOCTORS = "SET_DOCTORS"

// GET communication channels from API
export const SET_COMMUNICATION_CHANNELS = "SET_COMMUNICATION_CHANNELS"

// GET one doctor with its slug from API
export const SET_OPEN_CONSULTATION_DOCTOR = "SET_OPEN_CONSULTATION_DOCTOR"

// Removes selected open consultation doctor
export const RESET_OPEN_CONSULTATION_DOCTOR = "RESET_OPEN_CONSULTATION_DOCTOR"

// Set doctor in store
export const SET_CHOSEN_DOCTOR = "SET_CHOSEN_DOCTOR"

// Set consultation type in store
export const SET_CHOSEN_CONSULTATION_TYPE = "SET_CHOSEN_CONSULTATION_TYPE"

// Set medium type in store
export const SET_CHOSEN_MEDIUM = "SET_CHOSEN_MEDIUM"

// Set consultation specialty in store
export const SET_FOCUSED_SPECIALTY = "SET_FOCUSED_SPECIALTY"

// POST consultation to API with notifications
export const SET_CONSULTATION_FROM_PROFILE = "SET_CONSULTATION_FROM_PROFILE"

// POST consultation to API
export const SET_CONSULTATION = "SET_CONSULTATION"

// Add new consultation specialty after payment
export const STORE_PAID_CONSULTATION_SPECIALTY = "STORE_PAID_CONSULTATION_SPECIALTY"
export const STORE_PENDING_CONSULTATION_SPECIALTY = "STORE_PENDING_CONSULTATION_SPECIALTY"

// GET consultations to API
export const GET_CONSULTATIONS = "GET_CONSULTATIONS"

// Get last finished consultation
export const GET_LAST_FINISHED_CONSULTATION = "GET_LAST_FINISHED_CONSULTATION"

// GET consultation to API
export const UPDATE_FOCUSED_CONSULTATION = "UPDATE_FOCUSED_CONSULTATION"

// Update local consultation
export const UPDATE_CONSULTATION = "UPDATE_CONSULTATION"

// Update remote consultation with SSN
export const UPDATE_CONSULTATION_WITH_SSN = "UPDATE_CONSULTATION_WITH_SSN"

// GET new consultation to API
export const GET_NEW_CONSULTATION = "GET_NEW_CONSULTATION"

// POST consultation payment to API
export const SEND_CONSULTATION_PAYMENT = "SEND_CONSULTATION_PAYMENT"

// GET last consultation
export const GET_LAST_CONSULTATION = "GET_LAST_CONSULTATION"

// GET survey by consultationSpecialty and last pre diag if any
export const GET_SURVEY_AND_SUBMISSION = "GET_SURVEY_AND_SUBMISSION"

// SET Basically used for therapy surveys
export const SET_SURVEY_SUBMISSION = "SET_SURVEY_SUBMISSION"

// GET prescriptions to API
export const GET_PRESCRIPTIONS = "GET_PRESCRIPTIONS"
export const GET_PRESCRIPTION = "GET_PRESCRIPTION"

export const SHOW_PRESCRIPTION = "SHOW_PRESCRIPTION"

export const GET_PRESCRIPTION_PDF = "GET_PRESCRIPTION_PDF"

// Set focused prescription to use for ordering
export const SET_FOCUSED_PRESCIPTION = "SET_FOCUSED_PRESCIPTION"

// POST to create an order to receive prescription by postal mail
export const CREATE_PRESCRIPTION_DELIVERY = "CREATE_PRESCRIPTION_DELIVERY"

// POST to confirm and order to receive prescription by postal mail
export const FINALIZE_PRESCRIPTION_DELIVERY = "FINALIZE_PRESCRIPTION_DELIVERY"

// POST to start a purchase for a prescription serialization
export const START_PURCHASE_PRESCRIPTION_DOWNLOAD =
  "START_PURCHASE_PRESCRIPTION_DOWNLOAD"

// POST to finalize purchase a prescription serialization
export const PURCHASE_PRESCRIPTION_DOWNLOAD = "PURCHASE_PRESCRIPTION_DOWNLOAD"

// GET survey from API
export const SET_SURVEY = "SET_SURVEY"

// POST to create a patient
export const CREATE_PATIENT = "CREATE_PATIENT"

// UPDATE patient profile
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PATIENT_DATA = "UPDATE_PATIENT_DATA"

// PATCH patient profile to upload documents
export const UPLOAD_MEDICAL_DOCUMENT = "UPLOAD_MEDICAL_DOCUMENT"

// UPDATE patient profile
export const DELETE_CREDIT_CARD = "DELETE_CREDIT_CARD"

// Set survey object in store
export const SET_SURVEY_OBJECT = "SET_SURVEY_OBJECT"

// Set survey page in store
export const SET_SURVEY_QUESTION_NAME = "SET_SURVEY_QUESTION_NAME"

// Set personal data in store
export const SET_PERSONAL_DATA = "SET_PERSONAL_DATA"

// change personal data first and last name in store
export const SET_EXTRA_PERSONAL_DATA = "SET_EXTRA_PERSONAL_DATA"

// Set chosen time slot in store
export const SET_CHOSEN_TIME_SLOT = "SET_CHOSEN_TIME_SLOT"

// Set personal data in store
export const SET_TERMS_OPTIN = "SET_TERMS_OPTIN"

// Set personal data in store
export const SET_SOCIAL_SECURITY_MODE = "SET_SOCIAL_SECURITY_MODE"

// Get Pharmacies
export const GET_PHARMACIES = "GET_PHARMACIES"

// Get Pharmacy
export const GET_PHARMACY = "GET_PHARMACY"

// Receive new message
export const APPEND_MESSAGE = "APPEND_MESSAGE"

// POST Messages
export const CREATE_MESSAGE = "CREATE_MESSAGE"

// Get Messages
export const GET_MESSAGES = "GET_MESSAGES"

// Get Message
export const GET_MESSAGE = "GET_MESSAGE"

// Update survey via API
export const UPLOAD_SURVEY_OBJECT = "UPLOAD_SURVEY_OBJECT"

// Create account by accepting an invitation token
export const ACCEPT_INVITATION = "ACCEPT_INVITATION"

// Get available products
export const GET_PRODUCTS = "GET_PRODUCTS"

export const GET_TAXONS = "GET_TAXONS"

// Create cart
// export const CREATE_CART = 'CREATE_CART'

// Add to cart
export const BEGIN_CHECKOUT = "BEGIN_CHECKOUT"

export const CREATE_DELIVERY = "CREATE_DELIVERY"


export const GET_CART = "GET_CART"
export const CREATE_CART = "CREATE_CART"
export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const CHANGE_CART_ITEM_QUANTITY = "CHANGE_CART_ITEM_QUANTITY"

export const CREATE_ADDRESS = "CREATE_ADDRESS"

export const GET_ORDER = "GET_ORDER"

export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS"

export const PURCHASE_ORDER = "PURCHASE_ORDER"

// ACADEMY
export const GET_PROGRAMS = "GET_PROGRAMS"
export const GET_ENROLLMENTS = "GET_ENROLLMENTS"
export const START_ONBOARDING_WITH_PROGRAM = "START_ONBOARDING_WITH_PROGRAM"
export const ENROLL_PROGRAM = "ENROLL_PROGRAM"
export const START_RENEW_ENROLLMENT = "START_RENEW_ENROLLMENT"
export const INTERRUPT_ENROLLMENT = "INTERRUPT_ENROLLMENT"
export const PURCHASE_PROGRAM = "PURCHASE_PROGRAM"
export const START_LESSON = "START_LESSON"
export const EVALUATE_LESSON = "EVALUATE_LESSON"
export const APPLY_COUPON = "APPLY_COUPON"
export const SELECT_PROGRAM_VARIANT = "SELECT_PROGRAM_VARIANT"

export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS"
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION"

export const GET_SLEEP_CALENDAR = "GET_SLEEP_CALENDAR"
export const CREATE_SLEEP_CALENDAR_ITEM = "CREATE_SLEEP_CALENDAR_ITEM"

// 3DS CHALLENGE POPUP
export const START_3DS_CHALLENGE = "START_3DS_CHALLENGE"
export const END_3DS_CHALLENGE = "END_3DS_CHALLENGE"
export const MISPLACED_END_3DS_CHALLENGE = "MISPLACED_END_3DS_CHALLENGE"

export const GET_POST_CONSULTATION_DETAILS = "GET_POST_CONSULTATION_DETAILS"
export const START_UNIFIED_CHECKOUT = "START_UNIFIED_CHECKOUT"
export const STORE_UNIFIED_CHECKOUT_PHARMACIST = "STORE_UNIFIED_CHECKOUT_PHARMACIST"
export const STORE_PATIENT_CURRENT_COUNTRY = "STORE_PATIENT_CURRENT_COUNTRY"
export const SET_DRUGS_FUNNEL_CHOICE = "SET_DRUGS_FUNNEL_CHOICE"
export const SET_CURRENT_STORE = "SET_CURRENT_STORE"
export const GET_RECOMMENDED_PRODUCTS = "GET_RECOMMENDED_PRODUCTS"

// EVENTS
export const DID_START_PRE_PAYMENT_SURVEY = "DID_START_PRE_PAYMENT_SURVEY"
export const DID_TRACK_IDENTIFY = "DID_TRACK_IDENTIFY"

