import { handle } from 'redux-pack'
import * as A from 'constants/actions'

type Action = any

type State = {
  loading: boolean | null,
  success: boolean | null,
  error: string | null,
}

const initialState: State = {
  loading: null,
  success: null,
  error: null,
}

export default (state = initialState, action: Action) => {
  const { payload } = action

  switch (action.type) {
    case A.GET_PHARMACY:
      return handle(state, action, {
        start: prevState => ({
          ...prevState, loading: true, error: null, success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          pharmacy: payload.pharmacy,
          success: true,
        }),
      })
    case A.GET_PHARMACIES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState, loading: true, error: null, success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          collectionPharmacies: payload.pharmacies,
          success: true,
        }),
      })
    default:
      return state
  }
}
