import FBLegacyTracker from './FBLegacyTracker'
import SnapchatTracker from './SnapchatTracker'
import TiktokTracker from './TiktokTracker'
import SegmentTracker from './SegmentTracker'
import CompositeTracker from './CompositeTracker'

class CharlesTracker extends CompositeTracker {
  constructor() {
    super([
      new SegmentTracker(),
      new FBLegacyTracker(),
      new SnapchatTracker(),
      new TiktokTracker(),
    ])
  }
}

export default new CharlesTracker()
