
import Dinero from 'dinero.js'

export const money = (amount: string | number): Dinero.Dinero => {

  const floatAmount = parseFloat(amount.toString())
  const cents = floatAmount * 100
  const integerCents = Math.round(cents)
  return Dinero({ amount: integerCents })
}
