import { isUndefined, first } from 'lodash'
import T from 'types'

function getBrowserInfo(): T.MangopayBrowserInfo {
  const navigator = window.navigator
  const screen = window.screen
  return {
    javaEnabled: navigator.javaEnabled(),
    language: navigator.language,
    colorDepth: screen.colorDepth,
    screenHeight: screen.height,
    screenWidth: screen.width,
    timeZoneOffset: new Date().getTimezoneOffset().toString(),
    userAgent: navigator.userAgent,
    javascriptEnabled: true
  }
}


export const createPaymentRequestPayload = (
  paymentSourcePayload: T.PaymentPayload,
): T.PaymentRequestPayload => {

  if (isUndefined((paymentSourcePayload as T.ExistingCardPayload).existingCard)) {
    const payload = paymentSourcePayload as T.PaymentMethodWithData
    const paymentMethod = first(Object.keys(payload.paymentMethod)) as string
    const paymentMethodId = payload.paymentMethod[paymentMethod]
    return {
      paymentSource: {
        [paymentMethodId]: payload.data,
      },
      order: {
        paymentsAttributes: [
          {
            paymentMethodId,
          },
        ],
      },
      mangopayBrowserInfo: getBrowserInfo()
    }
  } else {
    const payload = paymentSourcePayload as T.ExistingCardPayload
    return {
      order: {
        existingCard: payload.existingCard,
      },
      mangopayBrowserInfo: getBrowserInfo()
    }
  }
}

export const getPaymentMethod = (
  paymentSourcePayload: T.PaymentPayload,
): string => {
  if (isUndefined((paymentSourcePayload as T.ExistingCardPayload).existingCard)) {
    const payload = paymentSourcePayload as T.PaymentMethodWithData

    if (isUndefined((payload.data as T.MangopayPaymentCardPayload).number)) {
      return 'Paypal'
    }
  }

  return 'MangopayPaymentCard'
}

declare global {
  interface Window {
    data: object
  }
}
