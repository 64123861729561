import { handle } from 'redux-pack'
import { without, concat, merge } from 'lodash'

type Action = {
  payload: object
  type: string
}

export default (initialState: any) => (state = initialState, action: Action, additionalState: {}) => {
  const { payload } = action
  return handle(state, action, {
    start: prevState => ({
      ...prevState,
      loading: true,
      error: false,
      success: null,
      actionLoading: concat(prevState.actionLoading, [action.type]),
    }),
    finish: prevState => ({
      ...prevState,
      loading: false,
      loaded: true,
      actionLoading: without(prevState.actionLoading, action.type),
    }),
    failure: prevState => ({ ...prevState, error: payload, success: false }),
    success: prevState => (merge({}, prevState, additionalState, payload))
  })
}
