import * as A from 'constants/actions'
import T from 'types'

type Action = any

const initialState: T.EventsStore = {
  didStartPrePaymentSurvey: false,
  shouldIdentify: false
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case A.DID_START_PRE_PAYMENT_SURVEY:
      return {
        ...state,
        didStartPrePaymentSurvey: true
      }
    case A.AUTH_SIGNIN:
      return {
        ...state,
        shouldIdentify: true
      }
    case A.DID_TRACK_IDENTIFY:
      return {
        ...state,
        shouldIdentify: false
      }
    default:
      return state
  }
}
