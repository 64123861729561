import { combineReducers } from 'redux'
import { i18nReducer as i18n } from 'react-redux-i18n'
import academy from './academy'
import auth from './auth'
import events from './events'
import consultations from './consultations'
import doctors from './doctors'
import messages from './messages'
import prescriptions from './prescriptions'
import pharmacy from './pharmacy'
import onboarding from './onboarding'
import surveys from './surveys'
import storefront from './storefront'
import unifiedCheckout from './unifiedCheckout'
import ux from './ux'

const rootReducer = combineReducers({
  academy,
  auth,
  events,
  consultations,
  doctors,
  messages,
  prescriptions,
  pharmacy,
  onboarding,
  surveys,
  ux,
  i18n,
  storefront,
  unifiedCheckout,
})

export default rootReducer
