import { handle } from 'redux-pack'
import * as A from 'constants/actions'
import { filter, get } from 'lodash'
import { CookiesHelper } from 'helpers'
import T from 'types'

type Action = any

type State = {
  profile: T.Profile
  signingIn: boolean
  waitingToken: boolean
  signedIn: boolean
  oneTimeAuthToken: string | null
  errors?: {
    email?: string
    password?: string
    token?: string
  }
}

const initialState: State = {
  profile: {},
  signingIn: false,
  waitingToken: false,
  oneTimeAuthToken: null,
  signedIn: CookiesHelper.getOauthToken('accessToken') !== '',
  errors: {},
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case A.STORE_ONE_TIME_AUTH_TOKEN: {
      return {
        ...state,
        oneTimeAuthToken: action.payload.oneTimeAuthToken
      }
    }
    case A.AUTH_GET_PROFILE:
      return handle(state, action, {
        start: prevState => ({ ...prevState }),
        finish: prevState => ({ ...prevState }),
        failure: prevState => ({ ...prevState }),
        success: prevState => ({ ...prevState, profile: action.payload.data }),
      })
    case A.AUTH_TOKEN:
      return handle(state, action, {
        start: prevState => ({ ...prevState, signingIn: true }),
        finish: prevState => ({ ...prevState, signingIn: false }),
        failure: prevState => ({
          ...prevState,
          waitingToken: false,
          errors: get(action.payload, "data.errors"), //, "errors"),
        }),
        success: prevState => ({ ...prevState, waitingToken: true, errors: {} }),
      })
    case A.AUTH_SIGNIN:
      return handle(state, action, {
        start: prevState => ({ ...prevState, signingIn: true }),
        finish: prevState => ({ ...prevState, signingIn: false, waitingToken: false }),
        failure: prevState => ({
          ...prevState,
          signedIn: false,
          errors: { token: "Mauvais code d'authentification, veuillez réessayer" },
        }),
        success: prevState => ({ ...prevState, signedIn: true }),
      })
    case A.AUTH_SIGNOUT:
      return handle(state, action, {
        start: prevState => ({ ...prevState, signingIn: true }),
        finish: prevState => ({ ...prevState, profile: {}, signingIn: false }),
        failure: prevState => ({ ...prevState, signedIn: true }),
        success: prevState => ({ ...prevState, signedIn: false }),
      })
    case A.UPLOAD_MEDICAL_DOCUMENT:
      return handle(state, action, {
        start: prevState => ({ ...prevState }),
        finish: prevState => ({ ...prevState }),
        failure: prevState => ({ ...prevState }),
        success: prevState => ({ ...prevState, profile: action.payload.data }),
      })
    case A.UPDATE_PROFILE:
      return handle(state, action, {
        start: prevState => ({ ...prevState }),
        finish: prevState => ({ ...prevState }),
        failure: prevState => ({ ...prevState }),
        success: prevState => ({ ...prevState, profile: action.payload.data }),
      })
    case A.DELETE_CREDIT_CARD:
      return handle(state, action, {
        start: prevState => ({ ...prevState }),
        finish: prevState => ({ ...prevState }),
        failure: prevState => ({ ...prevState }),
        success: prevState => ({
          ...prevState,
          profile: {
            ...prevState.profile,
            creditCards: filter(
              prevState.profile.creditCards,
              ({ id }: { id: number }) => id !== action.payload.id,
            ),
          },
        }),
      })
    case A.AUTH_UPDATE_FIELD:
      return {
        ...state,
        [action.field]: action.value,
      }
    case A.SET_SIGNED_OUT:
      return {
        ...state,
        signedIn: false,
      }
    default:
      return state
  }
}
