import { handle } from "redux-pack"
import { get } from "lodash"
import T from 'types'
import * as A from 'constants/actions'

type Action = any

const initialState: T.SurveysState = {
  loading: false,
}

export default (state = initialState, action: Action) => {
  const { payload } = action

  switch (action.type) {
    case A.SET_SURVEY_SUBMISSION:
      return {
        ...state,
        focusedSurveySubmission: payload,
      }
    default:
      return state
  }
}
