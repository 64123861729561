import T from 'types'
import SurveyTracker from '../interface'
import AbstractTracker from '../AbstractTracker'
import { specialtyToFamily } from 'utils/trackers/utils'
import { isNil } from 'lodash'

/**
 * Tracking to Facebook Pixel through Segment the exact events we were tracking to Facebook directly before implementing Segment.
 * We can't stop sending events from old tracking plan and start sending new events from new tracking plan because
 * a lot of ads are running on old events, and would be stopped if we stopped sending those events.
 * We can't replace old ads by new ads in one instant because ads optimization algorithms have a learning curve
 * over a few days - weeks.
 *
 * This FBLegacyTracker replaces FacebookTracker.
 * It sends events through Segment, but are only forwarded to Facebook Pixel
 * It has only the properties needed to not interrupted currently (28/09/2022) running ads.
 * All Facebook standards events are prefixed with FBLegacy to be clearly identified
 * They will be mapped to FB standard events in Segment Facebook Pixel destination configuration. That way we will be able to plug them off
 */

// Fixing [CHAR-1505]
// Custom conversions on Facebook are trigger with sexuality (not sexual).
// We stick to sexuality, so as not to lose Custom Conversion history.
const facebookCustomFamilyMapping: { [key: string]: string } = {
  sexual: "sexuality",
}
const facebookSpecialtyToFamily = (specialty: string) => {
  const family = specialtyToFamily(specialty)
  const customFamily = facebookCustomFamilyMapping[family]
  return !isNil(customFamily) ? customFamily : family
}

class FBLegacyTracker extends AbstractTracker implements SurveyTracker {

  config: string | undefined

  constructor() {
    super()
    this.config = process.env.REACT_APP_SEGMENT_WRITE_KEY
  }

  private enabled(): boolean {
    return (!!this.config) && (typeof window.analytics !== 'undefined')
  }

  public trackDidEnterOnboarding(trackAddToCart: boolean): void {
    if (trackAddToCart) {
      this.trackAddToCart()
    }
  }

  public trackAddToCart(): void {
    this.track('AddToCart', {})
  }

  public trackLead(specialty: string) {
    const family = facebookSpecialtyToFamily(specialty)
    this.trackFBLegacyLead('consultation', family)
  }

  public trackAcademyLead(program: T.Program): void {
    this.trackFBLegacyLead('academy', program.slug)
  }

  public trackCreatedConsultation(consultationType: string, medium: string) {
    this.track('InitiateCheckout', {})
  }

  public trackDidPayConsultation(
    transactionId: number | any,
    transactionPrice: string,
    consultationType: string,
    medium: string,
    specialty: string,
  ) {
    const family = facebookSpecialtyToFamily(specialty)
    this.trackFBLegacyPurchase('consultation', family, transactionPrice)
  }

  public trackDidPayAcademyProgram(
    transactionId: number | any,
    transactionPrice: string,
    program: T.Program,
    variant: T.Variant,
    subscriptionEvent?: string,
    orderType?: string
  ) {
    this.track('ViewContent', {
      content_category: 'academy',
      content_name: program.shortName,
    })
    this.trackFBLegacyPurchase('academy', program.shortName, transactionPrice)
  }

  private trackFBLegacyLead = (
    contentCategory: string,
    contentName: string,
  ) => {
    this.track("Lead", {
      content_category: contentCategory,
      content_name: contentName,
    })
  }

  private trackFBLegacyPurchase = (
    contentCategory: string,
    contentName: string,
    value: string,
  ) => {
    this.track("Purchase", {
      content_category: contentCategory,
      content_name: contentName,
      revenue: value,
      currency: 'EUR'
    })
  }

  private track = (name: string, data: any): void => {
    if (this.enabled()) {
      window.analytics.track(`FBLegacy${name}`, data, {
        integrations: {
          "All": false,
          "Facebook Pixel": true,
        }
      })
    }
  }
}

export default FBLegacyTracker
