import Cookies from 'js-cookie'
import { get } from 'lodash'

const oauthCookieKey = 'charles_oauth_token'

export const areCookiesEnabled = () => {

  let cookieEnabled = navigator.cookieEnabled ? true : false

  if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    const testKey = 'test-cookies-enabled'
    const testValue = 'test-cookies-enabled'
    Cookies.set(testKey, testValue)
    cookieEnabled = Cookies.get(testKey) === testValue ? true : false
    Cookies.remove(testKey)
  }
  return cookieEnabled
}

export const getCookie = (key: string) => Cookies.get(key)

export const getOauthToken = (key: string) =>
  get(JSON.parse(getCookie(oauthCookieKey) || '{}'), key, '')

export const setOauthToken = (data: Object) => Cookies.set(oauthCookieKey, JSON.stringify(data))

export const destroyOauthToken = () => Cookies.remove(oauthCookieKey)

export const getPureOauthAccessToken = () => getOauthToken('accessToken')

export const getOauthAccessToken = () =>
  `${getOauthToken('tokenType')} ${getOauthToken('accessToken')}`

export const getOauthRefreshToken = () => getOauthToken('refreshToken')

export default {
  areCookiesEnabled,
  destroyOauthToken,
  getCookie,
  getOauthToken,
  setOauthToken,
  getOauthAccessToken,
  getOauthRefreshToken,
  getPureOauthAccessToken,
}
