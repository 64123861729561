import { some, isUndefined, get } from 'lodash'
import T from 'types'
import store from '../../store'
import { getNumberOfPayments } from 'utils/models/variants'


const programRecommendationDatesMap = {
  master: 'sentMasterRecommendationAt',
  time: 'sentTimeRecommendationAt',
  sommeil: 'sentSommeilRecommendationAt',
  vaginisme: 'sentVaginismeRecommendationAt',
}

export const onboardingSpecialty = () => {
  return store.getState().onboarding.consultationSpecialty
}

export const specialtyToFamily = (soughtSpecialty: string): string => {
  const pathologies: T.SpecialtyCategory[] = store.getState().consultations.specialtyCategories
  const family = pathologies.find((family: T.SpecialtyCategory) => {
    return some(family.specialties, (specialty: T.ConsultationSpecialty) => (specialty.slug === soughtSpecialty))
  })
  return !isUndefined(family) ? family.slug : soughtSpecialty
}

export const storePatientAttributes = (): any => {
  return store.getState().onboarding.patientAttributes
}

export const storeProfileAttributes = (): any => {
  const profile: T.Profile = store.getState().auth.profile
  return {...profile}
}

export const patientGender = () => {
  return store.getState().onboarding.survey.gender
}

export const consultationPaymentMethod = () => {
  return store.getState().consultations.paymentMethod
}

export const consultationFocusedItem = () => {
  return store.getState().consultations.focusedItem
}

export const programOrderInstallment = (variant: T.Variant) => {
  const numberOfPayments = getNumberOfPayments(variant)

  if (numberOfPayments === 1) {
    return "one_time"
  }

  if (numberOfPayments > 3) {
    return  "recurring"
  }

  return "split_payment"
}

export const programRecommendationDate = (programName: string) => {
  const profile: T.Profile = store.getState().auth.profile
  return get(profile, get(programRecommendationDatesMap, programName), null)
}