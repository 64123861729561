import { handle } from 'redux-pack'
import { get } from 'lodash'
import T from 'types'
import * as A from 'constants/actions'

type Action = any

type State = {
  collection: T.Doctor[],
  doctor: T.Doctor | null,
  slot: Date | null,
  availableChannels: string[],
  loading: boolean | null,
  success: boolean | null,
  error: string | null,
}

const initialState: State = {
  collection: [],
  doctor: null,
  slot: null,
  availableChannels: [],
  loading: null,
  success: null,
  error: null,
}

export default (state = initialState, action: Action) => {
  const { payload } = action

  switch (action.type) {
    case A.SET_DOCTORS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState, loading: true, error: null, success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          collection: payload.doctors,
          success: true,
        }),
      })
    case A.SET_OPEN_CONSULTATION_DOCTOR:
      return handle(state, action, {
        start: prevState => ({
          ...prevState, loading: true, error: null, success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          collection: [payload.doctor],
          doctor: payload.doctor,
          success: true,
        }),
      })
    case A.SET_CHOSEN_DOCTOR:
      return {
        ...state,
        doctor: payload,
      }
    case A.SET_CHOSEN_TIME_SLOT:
      return {
        ...state,
        slot: payload,
      }
    // case when consultation is sent with the survey
    case A.SET_SURVEY:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          doctor: get(payload.consultation, 'fullDoctor'),
          slot:  get(payload.consultation, 'appointmentAt'),
        }),
      })
    case A.UPDATE_FOCUSED_CONSULTATION:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          doctor: get(payload.consultation, 'fullDoctor'),
          slot: get(payload.consultation, 'appointmentAt'),
        }),
      })
    case A.SET_COMMUNICATION_CHANNELS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState, loading: true, error: null, success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          availableChannels: get(payload.availableChannels, 'channels'),
          success: true,
        }),
      })
    default:
      return state
  }
}
