import T from 'types'
import { get, isNull, isUndefined } from 'lodash'

export const getIcon = (key: string): string | T.IconsMap => {
  if(isNull(key)){
    return ''
  }
  const foundIcon = get(icons, key)
  if(isUndefined(foundIcon)){
    console.error(`[ICONS DEBUGGER] Did not find ${key}`)
    throw new Error(`Implementation Error - icon not found ${key}`)
  }
  return foundIcon
}

export const icons: T.IconsMap = {
  birdHouse: require("./bird-house.svg"),
  newConsultation: require("./medical-specialist.svg"),
  patient: require("./medical.svg"),
  charles: require("./charles.svg"),
  checkShield: require("./check-shield.svg"),
  hospitalFirstAid: require("./hospital-first-aid.svg"),
  shipmentBox: require("./shipment-box.svg"),
  chatIcon: require("./chat-icon.svg"),
  logout: require("./logout.svg"),
  truck: require("./truck.svg"),
  orderTruck: require("./order-truck.svg"),
  play: require("./play.svg"),
  flag: require("./france.svg"),
  phyto: require("./phyto.svg"),
  academy: require("./icone-espace-academy.svg"),
  medical: require("./icone-espace-médical.svg"),
  support: require("./icone-assistance.svg"),
  programTime: require("./icone-programme-time.svg"),
  programSommeil: require("./icone-programme-time.svg"), // TODO: change
  shoppingBagUse: require("./shopping-bag-use.svg"),
  account: require("./icone-compte.svg"),
  message: require('./chat.svg'),
  phone: require('./phone.svg'),
  prescription: require('./large-prescription.svg'),
  check: require('./check.svg'),
  greenCheck: require('./green-check.svg'),
  startConsultation: require('./start-consultation.svg'),
  download: require('./download.svg'),
  greenRoundCheck: require('./check-round-green.svg'),
  redRoundCross: require('./cross-round-red.svg'),
  france: require('./france-icon.svg'),
  clinic: {
    thumpUp: require('./clinic/40/icon-med-confiance-experts-square.svg'),
    check: require('./clinic/32/check.svg'),
    warning: require('./exclamation-warning.svg'),
    refresh: require('./clinic/32/icon-small-annulation-square.svg'),
    phone: require('./clinic/32/icon-small-assistance-square.svg'),
    pig: require('./clinic/32/icon-small-economies-square.svg'),
    book: require('./clinic/32/icon-small-faq-square.svg'),
    hardware: require('./clinic/32/icon-small-hebergeur-square.svg'),
    shield: require('./clinic/32/icon-small-icon-secure-square.svg'),
    info: require('./clinic/32/icon-small-infos-square.svg'),
    box: require('./clinic/32/icon-small-livraison-discrete-square.svg'),
    postalBox: require('./clinic/32/icon-small-livraison-traitement-perso-square.svg'),
    mail: require('./clinic/32/icon-small-mail-square.svg'),
    doctor: require('./clinic/32/icon-small-med-specialistes-fr-square.svg'),
    chat: require('./clinic/32/icon-small-messages-ecrits-square.svg'),
    chatGrey: require('./clinic/32/icon-small-messages-ecrits-square-grey.svg'),
    lock: require('./clinic/32/icon-small-paiement-securise-square.svg'),
    checklist: require('./clinic/32/icon-small-plan-traitement-complet-square.svg'),
    team: require('./clinic/32/icon-small-med-specialistes.svg'),
    greenSquarePhone: require('./clinic/32/icon-small-tel-square.svg'),
    yellowSquarePhone: require('./clinic/32/icon-small-telephone-square.svg'),
    pill: require('./clinic/32/icon-small-traitement-perso-square.svg'),
    camera: require('./clinic/32/icon-small-videoconference-square.svg'),
    download: require('./clinic/32/icon-small-download.svg'),
    profile: require('./clinic/profile.svg'),
    blackLines: require('./clinic/black-lines.svg'),
    blackUnderline: require('./clinic/black-underline.svg'),
    calendar: require('./clinic/56/icon-large-rendez-vous-confirme-square.svg'),
    envelope: require('./clinic/56/icon-small-mail-square.svg'),
    largeSpecialist: require('./clinic/56/icon-large-specialistes-fr-square.svg'),
    clock: require('./clinic/40/icon-med-sans-attente-rdv-square.svg'),
    largeBox: require('./clinic/80/icon-med-online-livraison-discrete-square.svg'),
    largeBoxWithTick: require('./clinic/80/icon-med-online-livraison-discrete-square-tick.svg'),
    pillsBottle: require('./clinic/pills-bottle.svg'),
    plusIcon: require('./clinic/plus-icon.svg'),
    minusIcon: require('./minus-icon.svg'),
    eye: require('./clinic/32/eye.svg'),
    hourglass: require('./clinic/32/hourglass.svg'),
    bulletCalendar: require('./clinic/32/bullet-calendar.svg'),
    chevron: require('./chevron-down.svg'),
    letter: require('./envelope.svg'),
    specialties: {
      sleep: require('./clinic/specialties/sleep.svg'),
      digestion: require('./clinic/specialties/digestion.svg'),
      sexuality: require('./clinic/specialties/sexuality.svg'),
      hair: require('./clinic/specialties/hair.svg'),
      weight: require('./clinic/specialties/digestion.svg'),
    },
  },
  blocks: {
    pharmacy: require('./prescription.svg'),
    identityDisorder: require('./identity-disorder.svg'),
    doctor: require('./stethoscope.svg'),
    phyto: require('./phyto.svg'),
    academy: require('./academy/full.svg'),
    letter: require('./exit-funnel/lettre.svg'),
    handList: require('./exit-funnel/liste-main.svg'),
    handPhone: require('./exit-funnel/main-iphone.svg'),
  },
  notifications: {
    success: require('./notif-picto-success.svg'),
    warning: require('./notif-picto-warning.svg'),
    error: require('./notif-picto-error.svg'),
    info: require('./notif-picto-info.svg'),
    close: require('./close.svg'),
  },
  shapes: {
    back: require('./arrow.svg'),
    burger: require('./burger.svg'),
    roundedInfo: require('./rounded-info.svg')
  },
  therapies: {
    hair: require('./clinic/therapies/hair.svg'),
    sleep: require('./clinic/therapies/sleep.svg'),
    digestion: require('./clinic/therapies/digestion.svg'),
    weight: require('./clinic/therapies/digestion.svg'),
    sexual: require('./clinic/therapies/sexual.svg'),
    charles_psycho_sexo: require('./clinic/therapies/psycho.svg'),
    charles_couple_therapy: require('./clinic/therapies/psycho.svg')
  },
  logo: {
    charles: require('./logo-charles-clinique.svg'),
    charlesCircular: require('./logo-charles-clinique-circular.svg'),
    mia: require('../images/mia-logo.svg'),
    gq: require('./logo/gq.svg'),
    franceInfo: require('./logo/france-info.svg'),
    franceInter: require('./logo/france-inter.svg'),
    leMonde: require('./logo/le-monde.svg'),
  },
  payment: {
    pointer: require('./payment/mouse-pointer.svg'),
    success: require('./payment/circle-success.svg'),
    failure: require('./payment/circle-failure.svg'),
  },
  emoji: {
    indexPointing: require('./index-gun.svg'),
  },
  setup: {
    allGood: require('./setup-all-good.svg'),
    error: require('./setup-error.svg'),
    arrowUpLeft: require('./setup-arrow-up-left.svg'),
    camera: require('./setup-camera.svg'),
    cameraMissing: require('./setup-camera-missing.svg'),
    cameraGood: require('./setup-camera-good.svg'),
    mic: require('./setup-mic.svg'),
    micMissing: require('./setup-mic-missing.svg'),
    micGood: require('./setup-mic-good.svg'),
  },
}
