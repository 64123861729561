// Keys and values of states from in DoctorSelection component
import { includes } from 'lodash'

import { IS_CHARLES_PLATFORM } from './config'

// keys of DoctorSelection'state
export const CONSULT = 'consult'
export const MEDIUM = 'medium'
export const SPECIALTY = 'specialty'

// values of DoctorSelection'state 'medium'
export const CHAT = 'chat'
export const PHONE = 'phone'
export const VISIO = 'visio'

// values of DoctorSelection'state 'consultation'
export const COMPLEX = 'complex'
export const SIMPLE = 'simple'

// charles
export const ERECTILE_DYSFUNCTION = 'erectile_dysfunction'
export const FERTILITY = 'fertility'
export const PREMATURE_EJACULATION = 'premature_ejaculation'
export const ANDROPAUSE = 'andropause'
export const LIBIDO = 'libido'
export const OTHER = 'other'

// charles non-medical
export const CHARLES_COUPLE_THERAPY = 'charles_couple_therapy'
export const CHARLES_PSYCHO_SEXO = 'charles_psycho_sexo'

// mia
export const PSYCHOLOGICAL_THERAPY = "psychological_therapy"
export const COUPLE_THERAPY = "couple_therapy"
export const REACH_ORGASM = "reach_orgasm"
export const SEXUAL_DESIRE = "sexual_desire"
export const SEXUAL_WELLNESS = "sexual_wellness"
export const PAIN = "pain"
export const MIA_OTHER = "mia_other"

export const IS_MEDICAL = (specialty: string | undefined) : boolean => {
  const nonMedicalSpecialties = [
    PSYCHOLOGICAL_THERAPY,
    COUPLE_THERAPY,
    REACH_ORGASM,
    SEXUAL_DESIRE,
    SEXUAL_WELLNESS,
    PAIN,
    MIA_OTHER,

    CHARLES_COUPLE_THERAPY,
    CHARLES_PSYCHO_SEXO
  ]
  return !includes(nonMedicalSpecialties, specialty)
}

export const DEFAULT_PATHOLOGY = IS_CHARLES_PLATFORM ? ERECTILE_DYSFUNCTION : SEXUAL_WELLNESS
