import { includes, isEmpty } from 'lodash'
import { TEST_CARDS } from 'constants/mangopay'


export const isCreditCardNumberValid = (value: any): boolean => {
  if (isEmpty(value)) { return false }

  const visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/
  const mastPattern = /^(?:5[1-5][0-9]{14})$/
  const amexPattern = /^(?:3[47][0-9]{13})$/
  const discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/
  const maestroPattern = /^(5[06-8]|6\d)\d{10,17}$/
  const rawValue = value.replace(/\D+/g, '')

  if (
    visaPattern.test(rawValue) ||
    mastPattern.test(rawValue) ||
    amexPattern.test(rawValue) ||
    discPattern.test(rawValue) ||
    maestroPattern.test(rawValue) ||
    includes(TEST_CARDS, rawValue)
  ) {
    return true
  } else {
    return false
  }
}

export const isCreditCardHolderNameValid = (value: any): boolean => {
  if (isEmpty(value)) { return false }

  const regexName = /^[\w\W]*\s[\w\W]*$/g
  return regexName.test(value)
}

export const isCreditExpiryDateValid = (value: any): boolean => {

  if (isEmpty(value)) { return false }

  const month = parseInt(value.substring(0, 2))
  if (month <= 0 && month > 12 ) { return false }
  const year = parseInt(`20${value.slice(-2)}`, 10)
  const expiry = new Date(year, month, 0, 0, 0, 0, 0)

  return (expiry > new Date())
}

export const isCreditCardCVVValid = (value: any): boolean => {
  const regex = /^\d{3}$/g
  return (regex.test(value))
}
