import * as A from 'constants/actions'
import T from 'types'
import * as Sentry from '@sentry/browser'
import { handle } from 'redux-pack'
import { xor } from 'lodash'

type Action = any

const initialState: T.UXStore = {

  sidebarActive: false,
  classNotification: '',
  notificationShow: false,
  notificationText: '',
  classPopup: '',
  popupShow: false,
  popupTitle: '',
  popupType: '',
  popupProps: {},
  activeLabels: [],
  redirectUrlAfterLogin: '',
  secureChallenge: undefined
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case A.UX_TOGGLE_SIDEBAR:
      return { ...state, sidebarActive: action.value }
    case A.TOGGLE_DROPDOWN_SIDEBAR_ELEMENT:
      return { ...state, activeLabels: xor(state.activeLabels, [action.payload])}
    case A.UX_SHOW_NOTIFICATION:
      if (action.classNotification === 'error') {
        Sentry.captureException(new Error(action.value))
      }
      return {
        ...state,
        classNotification: action.classNotification,
        notificationText: action.value,
        notificationShow: true,
      }
    case A.UX_HIDE_NOTIFICATION:
      return {
        ...state,
        classNotification: '',
        notificationShow: false,
      }
    case A.UX_SHOW_POPUP:
      return {
        ...state,
        classPopup: action.payload.classPopup,
        popupTitle: action.payload.popupTitle,
        popupType: action.payload.popupType,
        popupProps: action.payload.popupProps,
        popupShow: true,
      }
    case A.UX_HIDE_POPUP:
      return {
        ...state,
        popupShow: false,
      }
    case A.REDIRECT_AFTER_LOGIN:
      return {
        ...state,
        redirectUrlAfterLogin: action.payload.redirectUrlAfterLogin,
      }
    case A.REDIRECTED_AFTER_LOGIN:
      return {
        ...state,
        redirectUrlAfterLogin: null,
      }
    case A.STORE_LAST_FOCUSED_LOCATION:
      return {
        ...state,
        lastFocusedLocation: action.payload.lastFocusedLocation,
      }
    case A.START_3DS_CHALLENGE:
      return {
        ...state,
        secureChallenge: {
          gatewaySecureModeRedirectUrl: action.payload.gatewaySecureModeRedirectUrl,
          successAction: action.payload.successAction,
          failureAction: action.payload.failureAction,
        }
      }
    case A.END_3DS_CHALLENGE:
      return {
        ...state,
        secureChallenge: undefined
      }
    default:
      return state
  }
}
