import {
  isUndefined,
  filter,
  every,
  keys,
  find,
  reduce,
  groupBy,
  omit,
  map,
  values,
  Dictionary,
  maxBy,
  get,
} from 'lodash'
import T from 'types'
import { money } from 'utils/models/money'

export const variantOptionsAsObject = (variant: T.Variant | any) =>
  isUndefined(variant) ? {} : reduce(
    variant.optionValues,
    (acc, optionValue) => ({ ...acc, [optionValue.optionType.name]: optionValue.name }),
    {},
  )

export const findVariant = (variants: T.Variant[], optionValues: any): T.Variant | undefined => {
  const soughtValues = optionValues
  return find(variants, variant => {
    const variantOptions = variantOptionsAsObject(variant)
    // @ts-ignore
    return every(keys(soughtValues), key => variantOptions[key] == soughtValues[key])
  })
}

export const findVariants = (variants: T.Variant[], optionValues: any): T.Variant[] => {
  const soughtValues = optionValues
  return filter(variants, variant => {
    const variantOptions = variantOptionsAsObject(variant)
    // @ts-ignore
    return every(keys(soughtValues), key => variantOptions[key] == soughtValues[key])
  })
}

export const groupUnsubscribableAndSubscribableVariants = (
  variants: T.Variant[],
): T.SubscribableVariant[] => {
  // Pretty dangerous implementation of a groupby on multiple keys.
  // - Sensible to object's keys order
  // But it's fast and it works
  // TODO - Improve
  const variantsPaired: Dictionary<T.Variant[]> = groupBy(variants, (variant: T.Variant) => {
    const options = variantOptionsAsObject(variant)
    const mergeKeys = omit(options, 'subscribable')

    // This is the dangerous part.
    return JSON.stringify(mergeKeys)
  })
  const variantPairs = values(variantsPaired)
  // @ts-ignore
  const subscribableVariants: T.SubscribableVariant[] = map(
    variantPairs,
    (variantPair: T.Variant[]) => {
      const subscribableVariant = findVariant(variantPair, {
        subscribable: 'subscribable',
      })
      const normalVariant = subscribableVariant
        ? find(variantPair, (variant: T.Variant) => variant.id !== subscribableVariant.id)
        : variantPair[0]
      return {
        variant: normalVariant,
        subscribableVariant: subscribableVariant,
      }
    },
  )

  return subscribableVariants
}

export const variantQuantity = (variant: T.Variant) => {
  const quantityOptionValue = find(
    variant.optionValues,
    optionValue => optionValue.optionType.name === 'pills-per-box',
  )
  const quantity = quantityOptionValue ? parseInt(quantityOptionValue.name, 10) : 0
  return quantity
}

export const productFrequencyForVariant = (
  variant: T.Variant,
): T.SubscriptionFrequency | undefined => {
  const subscriptionFrequencies: T.SubscriptionFrequency[] = variant.product.subscriptionFrequencies

  const subscriptionFrequency = find(
    subscriptionFrequencies,
    (sf: T.SubscriptionFrequency) => sf.monthsCount === variantQuantity(variant),
  )
  return subscriptionFrequency
}

export const organizeProgramVariants = (
  program: T.Program,
  forPatients: boolean,
  withFreeEpisode: boolean,
): {
  stepByStepVariant: T.Variant
  fullSplitPayment: T.Variant
  fullOneTimePayment: T.Variant
} => {
  const allVariants = isUndefined(program) ? [] : program.product.variantsIncludingMaster
  const variants = filter(allVariants, (variant: T.Variant) => !(variant.discontinued || variant.hidden))
  const applicableVariants = findVariants(variants, {
    price_for_existing_patient: forPatients.toString(),
    with_free_episode: withFreeEpisode.toString(),
  })
  const fullOneTimePayment = findVariant(applicableVariants, {
    number_of_payments: 1,
  }) as T.Variant

  const fullSplitPayment = find(applicableVariants, (variant: T.Variant) => {
    const variantProperties = variantOptionsAsObject(variant)
    // @ts-ignore
    const numberOfPayments = parseInt(variantProperties['number_of_payments'])
    return numberOfPayments > 1 && numberOfPayments < 5
  }) as T.Variant

  const stepByStepVariant = find(applicableVariants, (variant: T.Variant) => {
    const variantProperties = variantOptionsAsObject(variant)
    // @ts-ignore
    const numberOfPayments = parseInt(variantProperties['number_of_payments'])
    return numberOfPayments >= 5
  }) as T.Variant

  return {
    stepByStepVariant,
    fullSplitPayment,
    fullOneTimePayment,
  }
}

export const getNumberOfPayments = (variant: T.Variant): number => {
  const variantOptions = variantOptionsAsObject(variant)
  // @ts-ignore
  const numberOfPayments = parseInt(variantOptions['number_of_payments'])
  return numberOfPayments
}

export const variantFullPrice = (variant: T.Variant): number => {
  return money(variant.price)
    .multiply(getNumberOfPayments(variant))
    .toUnit()
}

// return a checkoutVariantChoice for the given variant
export const createCheckoutVariantChoice = (variant: T.Variant) => {
  const variantOptions: any = variantOptionsAsObject(variant)
  const shouldSubscribe = variantOptions.subscribable === 'subscribable'
  const subscriptionFrequency = productFrequencyForVariant(variant)
  const options = shouldSubscribe && subscriptionFrequency
    ? {
      subscribe: shouldSubscribe,
      subscription_frequency_id: subscriptionFrequency.id,
      delivery_number: 1000000,
    } : undefined
  return { options, variant: variant, quantity: 1 }
}

// return a variant for the given product and duration
export const findRecommendedVariant = (product: any, recommendedDuration: number | undefined) => {
  const variantQuantity = (variant: any) => {
    const quantityOptionValue = find(
      variant.optionValues,
      optionValue => optionValue.optionType.name === 'pills-per-box',
    )
    return parseInt(quantityOptionValue.name, 10)
  }

  const recommendedVariant = find(
    product.variants,
    (variant: any) => variantQuantity(variant) === recommendedDuration,
  )

  if (!isUndefined(recommendedVariant)) {
    return recommendedVariant
  }
  // force to return the 3 month variant if no variant found matching the recommended duration
  // usually use this:  return maxBy(product.variants, variantQuantity)
  return find(product.variants, (variant: any) => variantQuantity(variant) === 3)
}

// return a variant id for the given product and duration
export const findRecommendedVariantId = (product: any, recommendedDuration: number | undefined) => {
  return findRecommendedVariant(product, recommendedDuration).id
}
