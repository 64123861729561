import { applyMiddleware, createStore, compose } from 'redux'
import { middleware as reduxPackMiddleware } from 'redux-pack'
import * as Sentry from '@sentry/browser'
import { ExtraErrorData } from '@sentry/integrations'
import createSentryMiddleware from 'redux-sentry-middleware'
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { isUndefined } from 'lodash'
import translations from '../I18n'
import reducer from '../reducers'
import { loadState, saveState } from 'services/local-storage'
import analyticsMiddleware from '../middlewares/analyticsMiddleware'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
const SENTRY_URL = process.env.REACT_APP_SENTRY_URL

if (!isUndefined(SENTRY_URL)) {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new ExtraErrorData()],
  })
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sentryMiddleWare = createSentryMiddleware(Sentry, {})
const middlewares = [thunk, reduxPackMiddleware, analyticsMiddleware, sentryMiddleWare]

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger())
}

const enhancer = composeEnhancers(applyMiddleware(...middlewares))

const persistedState = loadState()
const store = createStore(reducer, persistedState, enhancer)

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translations) as any)

store.dispatch(setLocale('fr') as any)

store.subscribe(() => {
  saveState(store.getState())
})

export default store
