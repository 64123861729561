import { handle } from "redux-pack"
import { first, get } from "lodash"
import build from 'redux-object'
import T from 'types'
import * as A from 'constants/actions'
import normalizedDataHandler from './helpers/normalizedDataHandler'

type Action = any

const initialState: T.UnifiedCheckoutStore = {
  orderId: undefined,
  pharmacistId: undefined,
  frenchCitizen: undefined,
  forPrescriptions: undefined,
  lastFinishedConsultationId: undefined,
  lastFinishedConsultationFetchedOnce: false
}

export default (state = initialState, action: Action) => {
  const { payload } = action

  switch (action.type) {
    case A.START_UNIFIED_CHECKOUT:
      return handle(state, action, {
        success: prevState => {
          const orders: T.OrderV2[] = build(payload, 'cart')
          const orderId = get(first(orders), 'id')
          return {
            ...prevState,
            orderId: orderId
          }
        }
      })
    case A.SET_CURRENT_STORE:
      return normalizedDataHandler(initialState)(state, action, {})
    case A.STORE_UNIFIED_CHECKOUT_PHARMACIST:
      return {
        ...state,
        pharmacistId: payload,
      }
    case A.STORE_PATIENT_CURRENT_COUNTRY:
      return {
        ...state,
        frenchCitizen: payload,
      }
    case A.SET_DRUGS_FUNNEL_CHOICE:
      return {
        ...state,
        forPrescriptions: payload,
      }
    case A.GET_LAST_FINISHED_CONSULTATION:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => {
          const consultations: T.Consultation[] = build(payload, 'consultation')
          const consultationId = get(first(consultations), 'id')
          return {
            ...prevState,
            lastFinishedConsultationId: consultationId,
            lastFinishedConsultationFetchedOnce: true
          }
        },
      })
    default:
      return state
  }
}
